import React from "react"
import { FaHome } from "react-icons/fa"

import { Layout, Seo, classes } from "../../layout"
import { FindOutMoreButton, IPhone, img } from "../../components"

export default function IndexPage(props) {
  const { heading, iphone } = classes
  const section = "p-6 py-8 md:px-16 max-w-5xl mx-auto"
  const sub_heading =
    "flex justify-left items-center mb-4 text-base sm:text-xl font-bold"
  const paragraph = "my-6"

  return (
    <Layout path={props.path}>
      <Seo title="Trailguide Landing Page" />

      <div className={section + " mt-24 sm:flex"}>
        <div>
          <h1 className={heading}>Trailguide Landing Page</h1>
          <p className={paragraph}>
            By having your own landing page in the Trailguide app you
            automatically get your own customized digital tourguide where your
            guests and visitors can find the available local tours and
            activities.
          </p>
          <p className={paragraph}>
            Your guests and visitors can easily browse and filter for local
            tours, activities, and available services they might need during
            their stay. They will have access to detailed tour information,
            images, maps, and of course live GPS navigation.
          </p>
          <p className={paragraph}>
            Your landing page will also have information about your destination,
            driving directions, web site, facebook page, email, and phone.
          </p>
          <p className={paragraph}>
            Trailguide users will also discover your landing page while using
            the app, find out about your existence and get access to your custom
            tours and activitivies not officially displayed in the app.
          </p>
          <FindOutMoreButton
            className="mt-4"
            href="https://trailguide.net/hallingdalrides/page"
            target="trailguide"
          >
            Hallingdal Rides on Trailguide
          </FindOutMoreButton>
        </div>
        <IPhone
          alt="Your own landing page on Trailguide"
          src={img("/website/trailguide-iphone-landingpage-rides.jpeg")}
          className={iphone}
        />
      </div>

      <div className={section}>
        <div className={sub_heading}>
          <Icon>
            <FaHome className="w-full h-full" />
          </Icon>
          <div>Landing page content</div>
        </div>
        <p className={paragraph}>
          Your landing page shows your name and logo along with the address and
          phone number. There are links to your website, facebook page, and
          driving directions. You have your own beautiful cover image showing
          off your region and text highlighting your offerings. There is a map
          giving an overview of all your tours and list your guests can browse
          through, just like a restaurant menu.
        </p>
        <div className="sm:flex justify-center mt-12">
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Landing page for Storefjell"
              src={img(
                "/website/trailguide-iphone-landingpage-storefjell.jpeg"
              )}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              The landing page of Storefjell with a beautiful image of their
              mountain surroundings. There are links to their website, facebook
              page, email, phone number, and driving directions.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Landing page of Hallingdal Rides"
              src={img("/website/trailguide-iphone-landingpage2.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              Users can filter for activities and easily find something suitable
              to them and their family.
            </div>
          </div>
          <div className="sm:w-1/3 mb-12">
            <IPhone
              alt="Landing page with local services"
              src={img("/website/trailguide-iphone-landingpage-services.jpeg")}
              className="mx-auto max-w-56"
            />
            <div className="mb-4 px-4 text-sm mx-auto max-w-sm italic">
              As a tourist destination you can showcase local businesses
              offering services to guests and visitors on your landing page.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

function Icon(props) {
  return (
    <div className="w-8 h-8 p-1 sm:w-10 sm:h-10 sm:p-2 mr-4 bg-blue-500 text-blue-100 rounded-full">
      {props.children}
    </div>
  )
}
